import React, { useState } from 'react';
import { ContentSearch } from '~/common/models';
import {
  FaqCategoryItem,
  FaqSearch,
  FaqViewModel,
} from '../../models/faqs-model';
import { search } from '~/common/services/search-service';

type FaqProviderContext = {
  searchFaqs: (q: string) => Promise<void>;
  setFaqsManual: (category: string, faqs: FaqSearch[]) => void;

  categories: FaqCategoryItem[];
  selectedCategory?: string;

  searchTerm: string | null;
  faqSearchResults: FaqSearch[] | null;
  contentSearchResults: ContentSearch[] | null;
  isSearchLoading: boolean;
};

export const FaqProvider = React.createContext<FaqProviderContext | null>(null);

export const useFaqStore = (): FaqProviderContext => {
  return React.useContext(FaqProvider) as FaqProviderContext;
};

export const FaqStore: React.FC<FaqViewModel> = (props) => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [selectedCategory] = React.useState<string | undefined>(
    props.selectedCategory
  );
  const [categories, setCategories] = useState<FaqCategoryItem[]>(
    props.categories
  );
  const [faqSearchResults, setFaqSearchResults] = useState<FaqSearch[] | null>(
    null
  );

  const [contentSearchResults, setContentSearchResults] = useState<
    ContentSearch[] | null
  >(null);

  const searchFaqs = async (query: string) => {
    try {
      setIsSearchLoading(true);

      const [faq, content] = await Promise.all([
        search<FaqSearch>(query, 'faq'),
        search<ContentSearch>(query, 'content'),
      ]);

      setSearchTerm(faq.searchTerm);
      setFaqSearchResults(() => faq.results);
      setContentSearchResults(() => content.results);
    } finally {
      setIsSearchLoading(false);
    }
  };

  const setFaqsManual = (category: string, faqs: FaqSearch[]) => {
    setFaqSearchResults(faqs);
    setSearchTerm(category);
    setContentSearchResults(null);

    const _categories = [...categories];
    _categories.forEach((c) => (c.isSelected = c.name === category));
    setCategories(_categories);
  };

  return (
    <FaqProvider.Provider
      value={{
        searchFaqs,
        setFaqsManual,
        categories,
        selectedCategory,
        searchTerm,
        faqSearchResults,
        contentSearchResults,
        isSearchLoading,
      }}
    >
      {props.children}
    </FaqProvider.Provider>
  );
};
