import React from 'react';
import groupby from 'lodash.groupby';
import styles from './index.module.scss';
import { useFaqStore } from '../faq-provider';
import { AccordionItem, Button } from '@ui-elements';
import { FaqSearch } from '../../models/faqs-model';

export default () => {
  const { searchTerm, faqSearchResults, contentSearchResults } = useFaqStore();
  const [categories, setCategories] = React.useState<
    Record<string, FaqSearch[]>
  >({});

  React.useEffect(() => {
    setCategories(groupby(faqSearchResults, (e) => e.category));
  }, [faqSearchResults]);

  return searchTerm ? (
    <div id="faq-search-result" className={styles.faqSearchResults}>
      {faqSearchResults?.length === 0 && contentSearchResults?.length === 0 ? (
        <div className={styles.faqSearchEmpty}>
          <img src="/scripts/ContentMedia/images/search-not-found.svg" />
          <h3>Hmmmm</h3>
          <p>Sorry, we can’t find what you’re looking for</p>
        </div>
      ) : (
        <>
          <div>
            {Object.keys(categories).map((key, index) => {
              return (
                <div key={index} className={styles.faqSearchCategory}>
                  <h3>{key}</h3>
                  {categories[key].map((item, itemIndex) => {
                    return (
                      <AccordionItem
                        key={itemIndex}
                        title={item.question}
                        content={item.answer}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
          {(contentSearchResults?.length || 0) > 0 && (
            <div className={styles.contentSearchResults}>
              <div>These pages might also be helpful...</div>
              <p>
                Showing results for <span>{searchTerm}</span>
              </p>

              {contentSearchResults?.map((content, index) => {
                return (
                  <div key={index} className={styles.contentSearchResultItem}>
                    <div>
                      <a href={content.url}>{content.title}</a>
                      <a
                        href={content.url}
                      >{`${location.origin}${content.url}`}</a>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: content.content }}
                    />
                  </div>
                );
              })}

              <div>
                <Button
                  buttonSize="large"
                  type="bordered"
                  href={`/content-search?q=${searchTerm}`}
                >
                  See full results
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  ) : null;
};
