import React from 'react';
import { Button } from '~/common/components/ui-elements';
import styles from './index.module.scss';

export default () => {
  return (
    <div className={styles.faqHelp}>
      <div className={styles.faqHelpContainer}>
        <h3>Need more help?</h3>
        <Button href="/contact-us">Contact Subaru</Button>
      </div>
    </div>
  );
};
