import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { scroller } from 'react-scroll';
import { FaqCategoryItem, FaqSearch } from '../../models';
import { Button } from '@ui-elements';
import { useFaqStore } from '../faq-provider';

type CategoryButtonProps = FaqCategoryItem & {
  onClick: () => void;
  isSelected: boolean;
};

const CategoryButton = (props: CategoryButtonProps) => {
  return (
    <div
      className={cn(styles.faqCategoryItem, {
        [styles.faqCategoryItemSelected]: props.isSelected,
      })}
      onClick={props.onClick}
    >
      <img src={props.icon} alt={props.name} />
      <p>{props.name}</p>
    </div>
  );
};

export default () => {
  const { setFaqsManual, categories, selectedCategory } = useFaqStore();

  const selectFaqCategory = (faqCategory: FaqCategoryItem) => {
    history.replaceState({}, '', `/faqs/${faqCategory.url}`);
    setFaqsManual(faqCategory.name, faqCategory.faqs);
    scrollToResults();
  };

  const getAllFAQs = () => {
    return categories.reduce<FaqSearch[]>((prev, cur) => {
      return [...prev, ...cur.faqs];
    }, []);
  };

  const scrollToResults = () => {
    scroller.scrollTo('faq-search-result', {
      delay: 100,
      offset: -100,
      smooth: true,
    });
  };

  React.useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory === 'All') {
        setFaqsManual('All', getAllFAQs());
      } else {
        const category = categories.find((x) => x.name === selectedCategory);
        if (category) setFaqsManual(category.name, category.faqs);
      }

      scrollToResults();
    }
  }, []);

  return (
    <div className={styles.faqCategories}>
      <div className={styles.faqCategoriesContent}>
        <h2>Browse by categories</h2>
        <div className={styles.faqCategoriesGrid}>
          {categories.map((item, index) => (
            <CategoryButton
              key={index}
              onClick={() => selectFaqCategory(item)}
              {...item}
            />
          ))}
        </div>
        <Button
          type="bordered"
          buttonSize="large"
          onClick={() => {
            history.replaceState({}, '', '/faqs/all');
            setFaqsManual('All', getAllFAQs());
            scrollToResults();
          }}
        >
          See all FAQs
        </Button>
      </div>
    </div>
  );
};
