import React, { FormEvent } from 'react';
import { Button } from '@ui-elements';
import { scroller } from 'react-scroll';
import { useFaqStore } from '../faq-provider';
import styles from './index.module.scss';

export default () => {
  const { searchFaqs, isSearchLoading } = useFaqStore();
  const [searchText, setSearchText] = React.useState<string>('');

  const searchSubmit = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    await searchFaqs(searchText);
    scroller.scrollTo('faq-search-result', {
      delay: 100,
      offset: -100,
      smooth: true,
    });
  };

  const onSearchTextChange = (value: string) => {
    setSearchText(value);
  };

  return (
    <div className={styles.faqSearch}>
      <div className={styles.faqSearchContainer}>
        <div className={styles.faqSearchContent}>
          <h1>frequently asked questions</h1>
          <div className={styles.faqSearchForm}>
            <p>What can we help with?</p>
            <form onSubmit={(e) => searchSubmit(e)}>
              <input
                name="search"
                type="text"
                maxLength={80}
                value={searchText}
                onChange={(e) => onSearchTextChange(e.target.value)}
              />
              <Button
                nativeType="submit"
                rounded={false}
                loading={isSearchLoading}
              >
                <img src="/scripts//images/magnify.svg" />
                Search
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
