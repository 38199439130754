import React from 'react';
import { PageLayout } from '~/common/components';
import { FaqViewModel } from './models';
import { FaqStore } from './components/faq-provider';
import FAQSearch from './components/faq-search';
import FAQSearchResult from './components/faq-search-result';
import FAQCategories from './components/faq-categories';
import FAQHelp from './components/faq-help';

const FAQs = (props: FaqViewModel) => {
  return (
    <FaqStore {...props}>
      <PageLayout>
        <FAQSearch />
        <FAQCategories />
        <FAQSearchResult />
        <FAQHelp />
      </PageLayout>
    </FaqStore>
  );
};

export default FAQs;
