interface SearchResponse<T> {
  count: number;
  results: T[];
  searchTerm: string;
}

export const search = <T>(q: string, f: string) =>
  fetch(`/api/search?q=${q}&filter=${f}`)
    .then((res) => res.json())
    .then((res) => res as SearchResponse<T>);
